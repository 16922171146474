import React, { ReactNode } from "react";
import { I18nProvider, Trans } from "@bookingcom/lingojs-react";
import { getI18NStore } from "@btransport/lingo-client-utils";
import { DictionaryObject } from "@bookingcom/lingojs-parser";
import { Title, Box, Container, Accordion, Text, useViewport, Grid, Card, Link, Divider } from "@bookingcom/bui-react";

export interface AccordionItem {
	content: string;
	divider: boolean;
	title: string;
}

export interface FaqProps {
	header: string;
	itemsLeft: AccordionItem[];
	itemsRight: AccordionItem[];
	language: string;
	lingoLocale: string;
	lingoTranslations: DictionaryObject;
}

const SubtitleLink: React.FC<{ children: ReactNode; language: string }> = ({ children, language }) => {
	const helpLink = `https://taxi.booking.com/help?locale=${language}`;
	return <Link href={helpLink}>{children}</Link>;
};

export const Faq: React.FC<FaqProps> = ({
	itemsLeft,
	itemsRight,
	header,
	lingoLocale,
	lingoTranslations,
	language,
}) => {
	const { isLarge } = useViewport();

	const i18NStore = getI18NStore({ locale: lingoLocale, translations: lingoTranslations });

	return (
		<I18nProvider store={i18NStore}>
			<Container>
				<Box
					mixin={{
						paddingInlineEnd: 0,
						paddingInlineStart: 0,
						paddingBlockStart: 14,
					}}
				>
					<Title
						title={header}
						subtitle={
							<Trans
								tag="lpc_rw_faqs_sub"
								variables={{ start_faq_link: `<0>`, end_faq_link: "</0>" }}
								// eslint-disable-next-line react/jsx-key
								components={[<SubtitleLink language={language}>Help</SubtitleLink>]} // Fix for missing children prop
							/>
						}
						color="inherit"
						variant="headline_2"
					/>
				</Box>
				<Grid
					mixin={{
						marginBlockEnd: 16,
					}}
				>
					{isLarge ? (
						<>
							<Grid.Column size="half">
								<Card fill>
									{itemsLeft.map(({ title, content, divider }) => (
										<>
											<Accordion
												titleContent={<Title title={title} />}
												mixin={{
													padding: 4,
												}}
											>
												<Text>{content}</Text>
											</Accordion>
											{divider && <Divider />}
										</>
									))}
								</Card>
							</Grid.Column>
							<Grid.Column size="half">
								<Card fill>
									{itemsRight.map(({ title, content, divider }) => (
										<>
											<Accordion
												mixin={{
													padding: 4,
												}}
												titleContent={<Title title={title} />}
											>
												<Text>{content}</Text>
											</Accordion>
											{divider && <Divider />}
										</>
									))}
								</Card>
							</Grid.Column>
						</>
					) : (
						<Grid.Column size="full">
							<Card fill>
								{itemsLeft.map(({ title, content }) => (
									<>
										<Accordion
											titleContent={<Title title={title} />}
											mixin={{
												padding: 4,
											}}
										>
											<Text>{content}</Text>
										</Accordion>
										<Divider />
									</>
								))}
								{itemsRight.map(({ title, content }) => (
									<>
										<Accordion
											mixin={{
												padding: 4,
											}}
											titleContent={<Title title={title} />}
										>
											<Text>{content}</Text>
										</Accordion>
										<Divider />
									</>
								))}
							</Card>
						</Grid.Column>
					)}
				</Grid>
			</Container>
		</I18nProvider>
	);
};
