/* eslint sort-keys: ["error", "asc", {"minKeys": 8}] */
import type { ETExperiments } from "@btransport/rides-et-middleware";

export const ET_EXPERIMENTS = {
	bcfSearchFormMfe: {
		title: "pbt_rbex_bcf_search_form_mfe",
		stages: {
			searchFormMFERendered: 0,
		},
		customGoals: {},
		enabled: true,
		manualTracking: true,
	},
	bcfFlightpickerMfe: {
		title: "pbt_rbex_bcf_flightpicker_mfe",
		stages: {
			flightPickerRendered: 0,
		},
		customGoals: {},
		enabled: true,
		manualTracking: true,
	},
	geniusBadges: {
		title: "pbt_coreex_web_genius_badges_merchandising",
		stages: {
			dDot: 1,
			mDot: 2,
			standardVehicleVisilble: 3,
			peopleCarrierVehicleVisilble: 4,
		},
		customGoals: {},
		enabled: true,
		manualTracking: true,
	},
	searchResultsReturns: {
		title: "pbt_rbew_search_results_returns",
		stages: {
			mainStage: 0,
			withAnchorAccomBooking: 1,
			withoutAnchorAccomBooking: 2,
			dDot: 3,
			mDot: 4,
		},
		customGoals: {
			addReturnSearchForm: 1,
			clickReturnBanner: 2,
			clickEditDateTimeBanner: 3,
			clickEditDateTimeSearchForm: 4,
		},
		enabled: true,
		manualTracking: true,
	},
	useNewAutocomplete: {
		title: "pbt_searchform_new_autocomplete",
		stages: {
			dDot: 1,
			mDot: 2,
		},
		customGoals: {},
		enabled: true,
		manualTracking: true,
	},
	preferredPartnerThreshold: {
		title: "pbt_rps_prefferred_partner_threshold",
		stages: {
			geniusDiscountEligible: 1,
		},
		customGoals: {},
		enabled: true,
	},
} as const satisfies ETExperiments;

export const ET_PERMANENT_GOALS = {
	searchResultsPageViewed: "taxis_search_results_page_view",
	verticalSearchResultsPageViewed: "tbu_vertical_search_results_view",
};

export const ET_VARIANTS = {
	baseVariant: 0,
	variant1: 1,
} as const;
